import React, {useState} from "react";
import NoteModalComponent from "./NoteModalComponent"; 

import SizeChartModalComponent from "./SizeChartModalComponent"; 
import FaqModalComponent from "./FaqModalComponent"; 
import GetInTouchModalComponent from "./GetInTouchModalComponent"; 

import PreviewModal from "./PreviewModalComponent"; 
import * as htmlToImage from 'html-to-image';


const PUBLIC_URL = process.env.PUBLIC_URL;

function formatNumberToTwoDecimals(number) {
	//return number;
    // Round to two decimal places
    let roundedNumber = Math.round(number * 100) / 100;
    // Ensure two decimal places are always shown
    let formattedNumber = roundedNumber.toFixed(2);
    return formattedNumber;
}

async function get_preview_image(uploaded_image){
	var node = document.getElementById('zoom-container');
	var undo_redo_action = document.getElementById('undo-redo-action');
	var magnify = document.getElementById('magnify');
	var imagedraggablecontainer = document.getElementById('image-draggable-container');

	if(uploaded_image == ''){
		node.removeChild(imagedraggablecontainer);
	}
	
	undo_redo_action.setAttribute("style", "display:none;");
	magnify.setAttribute("style", "display:none;");
	
	let preview_image = 'test';
	
	return htmlToImage.toPng(node)
	  	  	.then(function (dataUrl) {
		  		 //console.log("Data url : ",dataUrl);
	  	  		
		  		undo_redo_action.setAttribute("style", "display:block;");
		    	magnify.setAttribute("style", "display:block;");
		    	node.appendChild(imagedraggablecontainer);
		    	return preview_image  = dataUrl
		  		    
		  	}).catch(function (error) {
		  		console.error('image : oops, something went wrong!', error);
		  	});
	
	//return preview_image;
	
}


const SizeList = (props) => { 
	
	let min_quantity = props.min_quantity;
	
	
	let lists = [0];
	//lists.push();
	for (var i=min_quantity; i < 5000; i++) {
        lists.push(i);
    }
	
	return (
		<>
		{lists.map((row, index) => (
      	  <option key={index} value={row}>{row}</option>
		))}
		</>
	)
	
}

function Rightside(props) {
	
	const home = props.home;
	
	const [total,setTotal] = useState(0);
	const [sizeTotal,setSizeTotal] = useState([]);
	
	//console.log("Sizes : ", home.state.product.sizes);
	const [error, setError] = useState('');
	const [agree, setAgree] = useState(false);

	const [sizeValue,setSizeValue] = useState([]);
	
	const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
	const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
	const [isSizeChartModalOpen, setIsSizeChartModalOpen] = useState(false);
	const [isFaqModalOpen, setIsFaqModalOpen] = useState(false);
	const [isGetInTouchModalOpen, setIsGetInTouchModalOpen] = useState(false);

	const deliveryText1 = "I understand custom products take 1-2 WEEKS"
	const deliveryText2 = "I understand custom products take 2-4 WEEKS"
	

	const skus = {
		'CUSTOM_PEARTOP':true,
		'CUSTOM_SLOUCH':true,
		'CUSTOM_BEANIE':true,
		'CUSTOM_SCARF':true,
		'CUSTOM_SCARF_2':true,

	}
	
	const [deliveryText,setDeliveryText] = useState((home.state.sku in skus)?deliveryText2:deliveryText1)


	function previewClose(){
		setIsPreviewModalOpen(false);
	}

	function noteClose(){
		setIsNoteModalOpen(false);
	}
	
	function SizeChartClose(){
		setIsSizeChartModalOpen(false);
	}
	
	function FaqClose(){
		setIsFaqModalOpen(false);
	}
	function GetInTouchClose(){
		setIsGetInTouchModalOpen(false);
	}
	
	function setTotalPrice(){	
		let temp = 0
		
		for (const [key, row] of Object.entries(sizeTotal)) {
			
			//console.log(key,row);
		   let t = row.quantities * row.cust_price;
		   temp = t + temp;
		}
		
		setTotal(temp.toFixed(2));
	}
	
	function change1(row,quantities){
		//console.log("item : ",row,quantities);
		
		row['quantities'] = quantities;
	
		let tempSizeTotal = sizeTotal; //this is local state
		
		tempSizeTotal[row.nm] = row;
		
		setSizeTotal(tempSizeTotal); // store in state
		
		setTotalPrice(quantities,row.cust_price);
		
	}

	function changeSize(row,min_order_qty,index,increase){
		//console.log("item : ",row,min_order_qty,index,increase);
		let quantities = 0;
		let tempSizeValue = sizeValue;


		if(increase == true){
			if(tempSizeValue[index] == undefined || tempSizeValue[index] == 0){
				quantities = Number(min_order_qty);
				tempSizeValue[index] = Number(min_order_qty);
			}
			else{
				tempSizeValue[index] = tempSizeValue[index] + 1;
			}
		}
		else{
			if(tempSizeValue[index] == undefined || tempSizeValue[index] == 0){
				return false;
			}
			else{
				//console.log("index - ",tempSizeValue[index],min_order_qty);
				tempSizeValue[index] = tempSizeValue[index] - 1;
				if(tempSizeValue[index] <= min_order_qty){
					tempSizeValue[index] = 0;
				}
			}
		}
		setSizeValue(tempSizeValue);


		row['quantities'] = Number(tempSizeValue[index]);
	
		let tempSizeTotal = sizeTotal; //this is local state
		
		tempSizeTotal[row.nm] = row;
		
		setSizeTotal(tempSizeTotal); // store in state
		
		setTotalPrice(quantities,row.cust_price);
		
	}


	function agreeProduction(){
		if(agree){
			setAgree(false);
		}
		else{
			setAgree(true);
		}
	}
	
	async function placeOrder(){
		//console.log("AGree - ",agree);
		if(!agree){
			setError("Please accept with production time.");
			return false;
		}

		setError('');
		home.http.loader(true);
		
		let order_images = {};
		let order_data = [];
		let not_order = true;
		
		
		let uploaded_image = document.querySelector('.dragImage').getAttribute("src");
		let product_preview_url = await get_preview_image(uploaded_image)

		//console.log("uploaded_image " ,uploaded_image,product_preview_url)
		
		//return false;
		//console.log("home : ",home.state.pattern.layers)
		
		let layers = home.state.pattern.layers;
		//let product_colors = home.state.product.colors;
		let product_colors = home.state.product.color_names;
		let colors = {};
		
		for (const [key,row] of Object.entries(layers)) {
			
			if(row.isEditable === "1"){
				if(product_colors.hasOwnProperty(row.color)){
					colors[row.name] = product_colors[row.color];
				}
				else{
					colors[row.name] = row.color;
				}
				
			}
			
		}
		
		//console.log("Order place data : ",product_preview_url)
		
		//let full_url = uploaded_image
		
		order_images['sku'] = home.state.sku;
		order_images['full_url'] = uploaded_image;
		order_images['product_preview_url'] = product_preview_url;
		   
		for (const [key,row] of Object.entries(sizeTotal)) {
			
		   let temp = {};
		   
		   temp['full_url'] = null;
		   temp['product_preview_url'] = null;
		   temp['note'] = (home.state.note !== undefined)?home.state.note:null;
		   
		   if(home.state.builderText || home.state.builderText == ''){
			   temp['logo_text'] = (home.state.builderText)?home.state.builderText:null;
			   temp['logo_placement'] = (home.state.location)?home.state.location:null;
			   temp['text_placement'] = (home.state.location)?home.state.location:null;
			   temp['text_color'] = home.state.builderTextColor.color;
			   temp['font_family'] = home.state.builderTextColor.fontFamily;
		   }
		   else{
			   temp['logo_text'] = null;
			   temp['logo_placement'] = (home.state.location)?home.state.location:null;
			   temp['text_placement'] = null;
			   temp['text_color'] = null;
			   temp['font_family'] = null;
		   }
		   
		   
		   
		   temp['parent_sku'] = home.state.sku;
		   temp['sku'] = row.sku;
		   
		   temp['pattern'] = home.state.pattern.name;
		   temp['colors'] = colors;
		   
		   //temp['total'] = home.state.pattern.id;
		   temp['stirrup_length'] = home.state.pattern.style_key;
		   
		   temp['price'] = row.cust_price;
		   temp['qty'] = row.quantities;
		   temp['size'] = row.size;
		   
		   order_data.push(temp);
		   
		   not_order = false;
		}
		
		
		if(not_order){
			setError("Please select size and quantity.");
			home.http.loader(false);
			return false;
		}
		// alert("redirect : "+home.state.redirect);
		// console.log("redirect url = ",home.state.redirect);
		// return false;
		//console.log({state:home.state,order:order_data})
		
		home.http.post("product/placeOrder/"+home.state.session,{images:order_images,order:order_data})
		.then((res) => {
			if (res.data) {
				//console.log("redirect url = ",home.state.redirect);
				if(home.state.redirect){
					window.location = home.state.redirect+'/showcart.asp?createsessioncookie=0&s_key='+home.state.session;
				}
				else{
					window.location = 'https://pearsox.com/showcart.asp?createsessioncookie=0&s_key='+home.state.session;
				}
				home.http.loader(false);
				 //console.log("response data : ",res.data);
				 //window.location = 'https://pearsox.com/showcart.asp?createsessioncookie=0&s_key='+home.state.session;
				 //window.location = 'https://pearsox-sandbox2.cimstaging.com/showcart.asp?createsessioncookie=0&s_key='+home.state.session;
				 //home.http.loader(false);
			}
		}).catch(function (errorr) {
			home.http.loader(false);
			setError("Something wrong!");
		});
	}
	
	
  return (
		  <div className="col col-md-6 col-lg-3 right-sidebar">
		  
		  <PreviewModal filename={home.state.pattern.sample_design} show={isPreviewModalOpen} close={previewClose}></PreviewModal>
		  <NoteModalComponent home={home} show={isNoteModalOpen} close={noteClose}></NoteModalComponent>
		  <SizeChartModalComponent data={home.state.product.sizechart} show={isSizeChartModalOpen} close={SizeChartClose}></SizeChartModalComponent>
		  
		  <GetInTouchModalComponent show={isGetInTouchModalOpen} close={GetInTouchClose}></GetInTouchModalComponent>
		  <FaqModalComponent home={home} show={isFaqModalOpen} close={FaqClose}></FaqModalComponent>
		  
	        <section>
	          <div className="sox-information">
	            <div className="sox-informationtext">
	              <h1>{home.state.product.name}</h1>
	              {home.state.pattern && <div className="sub-heading">{home.state.pattern.name}</div> }
	            </div>
	            
	            <div className="unit-price mb10" style={{display:"none"}}>
	              <span className="heading">Unit Price</span>
	              <span>$14.00</span>
	            </div>
	            
	            {home.state.product.contents && 
	            <div className="mb10">
	              <div className="heading">Contents</div>
	              <div className="sub-heading">{home.state.product.contents}</div>
	            </div>
	            }
	            
	            {home.state.product.details && 
	            <div className="details mb10">
	              <div className="heading">Details</div>
	              <div className="sub-heading">{home.state.product.details}</div>
	              <ul style={{"display":"none"}}>  
	                <li>*Low Minimums (12 per size)</li>
	                <li>*Fast Turnaround Times</li>
	                <li>*High Performance Products</li>
	              </ul>
	            </div>
	            }

			   {(home.state.pattern && home.state.pattern.isLayers) &&
	            <div className="details mb10">
	              <div className="heading">Colors</div>
				  {Object.keys(home.state.pattern.layers).map((key, index) => ( 
					(home.state.pattern.layers[key].isEditable === "1" &&
					<div key={index} className="sub-heading">{home.state.pattern.layers[key].name} / {home.state.product.colors[home.state.pattern.layers[key].color.toLowerCase()]}</div>
					)
                  ))}
	            </div>
	            }

				

				{home.state.pattern.description && 
	            <div className="details mb10">
	              <div className="heading">Pattern Description</div>
	              <div className="sub-heading">{home.state.pattern.description}</div>
	            </div>
	            }

				{home.state.pattern.sample_design && 
	            <div className="details mb10">
	              <div className="heading">Sample Design</div>
	              <div className="sub-heading">
					<img style={{cursor:'pointer'}} alt="" src={home.state.pattern.sample_design} width="100" height="100" onClick={(e) => {setIsPreviewModalOpen(true)}}/>
				  </div>
	            </div>
	            }

	            
	            <div className="note-text mb10" onClick={(e) => {setIsNoteModalOpen(true)}}>Note for Our Designers</div>
	            
	            <div className="size-chart mb10">
	              <span className="heading">Select Size</span>
	              <span onClick={(e) => {setIsSizeChartModalOpen(true)}}>
	                <div>
	                  <svg xmlns="http://www.w3.org/2000/svg" width="24.039" height="24.039" viewBox="0 0 24.039 24.039">
	                    <g id="noun-ruler-3821723" transform="translate(-103.138 61.524) rotate(-45)">
	                      <path id="Path_263" data-name="Path 263" d="M120.216,53.906,112,45.686l-.52.52,8.22,8.22h0a1.7,1.7,0,0,0,2.4,0l2.736-2.729a.368.368,0,0,0,0-.52L111.676,38.035a.369.369,0,0,0-.52,0l-2.729,2.729a1.7,1.7,0,0,0,0,2.4l2,2,.52-.522-2-2a.96.96,0,0,1,0-1.356l2.468-2.468.8.8-1.853,1.853.525.52,1.848-1.853,1.229,1.229-.972.972.52.513.972-.972,1.228,1.238-.97.972.52.52.97-.972,1.231,1.229-1.851,1.853.52.52,1.853-1.853,1.229,1.231-.97.97.52.52.97-.969,1.231,1.229-.965.97.52.52.972-.972,1.229,1.231-1.858,1.846.52.52,1.851-1.846.8.8-2.468,2.468a.961.961,0,0,1-1.356,0Z" transform="translate(0 0)" />
	                    </g>
	                  </svg>
	                  Size Chart
	                </div>
	              </span>
	            </div>
	            
	            <div className="custom-list mb10">
	              <ul>
	              
	              {home.state.childProducts && home.state.childProducts.map((row, index) => (
	                <li key={index}>
	                  <span style={{display:"none"}} className="addToCart" onClick={(e) => {setTotalPrice(row.cust_price)}} >+</span>
	                  <span className="cltext">{row.nm}</span>
					
					  <div className="sizeinput">
					  	<img onClick={e => changeSize(row,row.min_order_qty, index,false)} alt="" src={PUBLIC_URL + '/assets/images/minus-svgrepo-com.svg'}  />
					    <input type="text" value={(sizeValue[index])?sizeValue[index]:0}/>
						<img onClick={e => changeSize(row,row.min_order_qty, index,true)} alt="" src={PUBLIC_URL + '/assets/images/plus-svgrepo-com.svg'}  />
					    
					  </div>

	                  <span className="price">${formatNumberToTwoDecimals(row.cust_price)}</span>
	                  
	                </li>
	              ))}
	                
	              </ul>
	            </div>
	            
	            
	            <div className="calculative-price mb10">
	              <div className="total">Total</div>
	              <div className="amount-text">
	                <h6>Amount</h6>
	                <h5>${formatNumberToTwoDecimals(total)}</h5>
	              </div>
	            </div>
	            <div className="production-time mb10">
	              <span>Production Time</span>
	              <span className="custom-tooltip">
	                <img alt="" src={PUBLIC_URL + '/assets/images/information.png'} />
	              </span>
	              <span className="tooltip-info">
	              All custom products are carefully built by our designers and top-of-the-line equipment to offer you 100% satisfaction.
	              </span>
	            </div>

	            <div className="checkbox mb10" >
	              <label >{deliveryText}
	                <input type="checkbox" onClick={(e) => agreeProduction()}/>
	                <span className="checked" />
	              </label>
	            </div>

	            <div className="faq-touch mb10">
	              <div className="faq"><span onClick={(e) => {setIsFaqModalOpen(true)}}>FAQS</span></div>
	              <div className="touch" onClick={(e) => {setIsGetInTouchModalOpen(true)}}><span>Get in Touch</span></div>
	            </div>

				{error && 
					<div><span className="error">{error}</span></div>
				}

	            <div className="place-order">
	              <button onClick={e => placeOrder()}><img alt="" src={PUBLIC_URL + '/assets/images/shopping-cart.png'} /> Place Order</button>
	            </div>
	          </div>
	        </section>
	      </div>
  );
}

export default Rightside;




            
            
            
